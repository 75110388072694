/******** font face *********/

@font-face {
    font-family: 'latoblack';
    src: url('./fonts/lato-black-webfont.woff2') format('woff2'),
    url('./fonts/lato-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoblack_italic';
    src: url('./fonts/lato-blackitalic-webfont.woff2') format('woff2'),
    url('./fonts/lato-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latobold';
    src: url('./fonts/lato-bold-webfont.woff2') format('woff2'),
    url('./fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latobold_italic';
    src: url('./fonts/lato-bolditalic-webfont.woff2') format('woff2'),
    url('./fonts/lato-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latohairline';
    src: url('./fonts/lato-hairline-webfont.woff2') format('woff2'),
    url('./fonts/lato-hairline-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latohairline_italic';
    src: url('./fonts/lato-hairlineitalic-webfont.woff2') format('woff2'),
    url('./fonts/lato-hairlineitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoitalic';
    src: url('./fonts/lato-italic-webfont.woff2') format('woff2'),
    url('./fonts/lato-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latolight';
    src: url('./fonts/lato-light-webfont.woff2') format('woff2'),
    url('./fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latolight_italic';
    src: url('./fonts/lato-lightitalic-webfont.woff2') format('woff2'),
    url('./fonts/lato-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoregular';
    src: url('./fonts/lato-regular-webfont.woff2') format('woff2'),
    url('./fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
    background-color: #ffffff;
    font-family: 'latoregular';
}
.app .nav-link{
    color: #000000;
    text-transform: uppercase;
    font-family: "latobold";
}
.app .nav-link.active{
    background: none;
    color: #D5B177;
}
.app nav.navbar{
    z-index: 99999;
}
.main-banner{
    height: 184px;
    background-color: #D5B177;
    overflow: hidden;
}
.main-banner .page_title{
    position: relative;
    z-index: 99999;
    height: 100%;
    width: 100%;
    background: rgba(213,177,119, 0.6);
    /*background: linear-gradient(180deg, rgba(213,177,119,1) 0%, rgba(255,255,255,0.5) 100%);*/
}
.main-banner h2, .main-banner h1{
    margin: auto;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    font-family: 'latoitalic';
    padding-top: 75px;
    font-size: 28px;
    letter-spacing: 1.68px;
}
.app header ul.nav.nav-pills {
    padding-top: 15px;
}
.home-banner{
    /*background-image: url("../images/home_banner.png");*/
    /*background-size:cover;*/
    /*min-height: 400px;*/
    margin-top: -127px;
    position: relative;
    z-index: 1;
}
.app .nav.nav-pills{
    position: absolute;
    right: 0;
}
.display-mobile{
    display: none;
}

.app.app_home .nav-link, .app.app_home_default .nav-link {
    color: #ffffff;
}
.app.app_home .nav-link:hover, .app.app_home_default .nav-link:hover {
    color: #D5B177;
}
.home_button{
    /*position: absolute;*/
    /*bottom: 50px;*/
    margin: 0 auto;
    width: 100%;
    z-index: 99999;
}

.banner_action{
    position: absolute;
    left: 0%;
    right: 0%;
    margin: 0 auto;
    bottom: 90px;
    text-align: center;
}

.home_button a{
    padding: 12px 25px;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    color: #ffffff;
    background-image: url("../images/button_bg.png");
    text-decoration: none;
    letter-spacing: 1px;

    max-width: 330px;
    display: block;
    margin: 0 auto;
}
.home_button a:hover{
    border-color: #D5B177;
    color: #D5B177;
}
.main_text{
    padding-top: 50px;
    padding-bottom: 50px;
}
.main-footer{
    border-top: 1px solid #000000;
}
.main-footer a{
    color: #000000;
    text-decoration: none;
}
.main-footer a:hover{
    color: #D5B177;
}

.contact-form input[type=text], .contact-form input[type=email], .contact-form textarea{
    border-radius: 10px;
    border: 1px solid #000000;
    padding: 7px;
    font-size: 15px;
    letter-spacing: 1.5px;
}
.header-flag-icon{
    margin-left: 25px;
    opacity: 0.5;
    padding-top: 5px;
}
.header-flag-icon.active{
    opacity: 1;
}
.border-left-cfi{
    border-left: 1px solid #000000;
}
.year-cfi{
    text-align: right;
    margin-right: 10px;
    float: right;
    padding: 5px 20px;
    border: 2px solid #ffffff;
    font-size: 28px;
    font-family: 'latoregular';
    letter-spacing: 1.68px;

}
.year-cfi.active, .year-cfi:hover {
    border: 2px solid #D5B177;
    color: #D5B177;
    font-family: 'latobold';
}
.year-cfi:hover {
    cursor: pointer;
}
.yearInfo{
    display: none;
    padding-left: 55px;
}

.yearInfo.active{
    /*display: block;*/
}
.border-right-cfi{
    border-right: 2px solid #000000;
}
.yearInfo ul{
    padding-left: 15px;
    margin-bottom: 30px;
}
.yearInfo ul li a{
    color: #000000;
    letter-spacing: 0.96px;
}
.yearInfo ul li a:hover{
    color: #D5B177;
}
.yearInfo label{
    font-family: 'latobold';
    margin-bottom: 10px;
}
a.seeOtherYear{
    color: #000000;
    text-transform: uppercase;
}
.moreYearsList{
    display: none
}
.contact-form label{
    text-transform: uppercase;
    margin-right: 15px;
    margin-left: 5px;
    font-size: 18px;
    margin-top: -2px;
    font-family: 'latobold';
    letter-spacing: 1.8px;
}
#contact_title{
    display: inline-flex;
}
.contact-form input[type=radio] {
    border: 1px solid #000000;
    padding: 0.5em;
    -webkit-appearance: none;
    border-radius: inherit;
    height: 20px;
    width: 20px;
}

input[type=radio]:checked {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=) no-repeat center center;
    /*background-color: black;*/
    background-size: 9px 9px;
    border: 2px solid #000000;
}

input[type=radio]:focus {
    outline-color: transparent;
}
input#contact_captcha{
    margin-left: 15px;
    font-size: 16px;
    letter-spacing: 0.16px;
}
.form-errors ul{
    margin-top: 10px;
    list-style: none;
    padding-left: 0px;
    color: #9a3412;
}

.cfi-border{
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 10px;
}
#contact_submit:hover{
    background-color: #D5B177;
    border-color: #D5B177;
    color: #ffffff;
}

.app_home .main-footer, .app_home_default .main-footer{
    border: none;
}
.app_home .header-flag-icon, .app_home_default .header-flag-icon{
    padding-top: 0;
}
.app_home .header-flag-icon img, .app_home_default .header-flag-icon img{
    height: 40px;
    width: 40px;
}
.main-banner video{
    margin-top: -565px;
    position: relative;
    z-index: 1;
}
.app_contact_us form[name='contact'] {
    max-width: 674px;
    margin: 0 auto;
}
.app_contact_us #contact_submit{
    letter-spacing: 1.5px;
    font-size: 18px;
}
.main-header nav{
    margin: 0 20px;
}
.contact-form label.termsLabel{
    font-family: 'latoregular';
    text-transform: inherit;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.17px;
    line-height: 25px;
}
.contact-form p{
    font-size: 15px;
    line-height: 16px;
    margin-top: 5px;
}
.contact-form p a{
    color: #000000;
}
.pl-m-0{
    padding-left: 0px;
}
label[for='contact_title_0']{
    margin-right: 30px;
}
.app_login .form-control{
    border-radius: 10px;
    border: 1px solid #000000;
    padding: 7px;
    font-size: 15px;
    letter-spacing: 1.5px;
}
.app_login  button.btn.btn-lg.btn-default {
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 10px;
    background-color: transparent;
    font-size: 18px;
    text-transform: uppercase;
}
.app_login  button.btn.btn-lg.btn-default:hover {
    background-color: #D5B177;
    color: #ffffff;
    border: 1px solid #ffffff;

}
.app_login h1.h3.font-weight-normal{
    text-transform: uppercase;
}
.app_login form[name='login']{
    max-width: 674px;
    margin: 0 auto;
}
#contact_submit:disabled{
    cursor: not-allowed;
}
.app button.navbar-toggler {
    border: 2px solid black;
}
.app_contact_us i.fa-solid.fa-bars {
    color: black;
}
.home_button a h1{
    font-size: 1rem;
    margin: 0px;
}