@media only screen and (min-width: 760px ) and ( max-width:1030px) {
    .home_button.text-xl-center {
        text-align: center;
    }
    .main-footer{
      padding: 25px;
    }
}
@media only screen and (max-width: 620px) {
    .app .nav.nav-pills{
        position: inherit;
        right: inherit;
    }
    .home-banner{
        margin-top: 0px;
    }
    .display-mobile{
        display: block;
    }
    .display-desktop{
        display: none;
    }
    .app_home .app .nav-link, .app_home_default .app .nav-link  {
        color: #000000;
    }
    .app.app_home .nav-link, .app.app_home_default .nav-link {
        color:#000000;
    }
    li.nav-item{
        width: 100%;
    }
    
    input#contact_captcha {
        width: 145px;
        margin-left: 25px;
    }
    img.captcha_image{
        width:120px;
    }
    .contact-form label {
        display: inline;
    }
    .main-banner{
        height: 100px;
    }
    .main-banner video{
        margin-top: -10rem;
    }
    .main-banner h2, .main-banner h1{
        font-size: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .banner_action{
        bottom: 25px;
    }
    .home_button a{
        font-size: 14px;
        text-align: center;
        padding: 10px;
    }
    .yearInfo{
        padding-left: 30px;
    }

    .pl-m-0{
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    .app_login button.btn.btn-lg.btn-default {
        width: -webkit-fill-available;
    }
    .year-cfi{
        font-size: 20px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 410px) {
    .app_contact_us .contact-form{
        padding:30px !important;
    }
    .home_button a h1{
        font-size: 14px;
    }
    .home_button a{
        max-width: 300px;
    }
}